<script setup lang="ts">
import type { IPromo } from '@/types/types'

const props = defineProps({
  promo: {
    type: Object as PropType<IPromo>,
    required: true,
  },
})

const emit = defineEmits(['promoSelected', 'close'])

const progressValue = ref(100)

function handleSelectPromo() {
  emit('promoSelected', props.promo)
}

let intervalId: any = null

onNuxtReady(() => {
  intervalId = setInterval(() => {
    progressValue.value -= 1

    if (progressValue.value <= 0) {
      clearInterval(intervalId)

      emit('close')
    }
  }, 50)
})
</script>

<template>
  <div>
    <progress
      id="progress"
      :value="progressValue"
      max="100"
      class="h-1 rounded-t-lg w-fit transition-all duration-500 ease-in-out"
    />

    <div class="min-w-[342px] h-[64px] mb-8 bg-tertiary-50 rounded-lg overflow-hidden flex items-center justify-between gap-4 px-4 -mt-2 xl:shadow">
      <div class=" flex items-center gap-4">
        <Icon name="mdi:ticket-percent-outline" class="w-10 h-10 text-primary-400" />
        <p v-if="promo">
          Cupom de <span class="font-bold text-primary-400">{{ promo.value }}%OFF</span> aqui
        </p>
      </div>
      <button class="font-bold text-primary-400" type="button" @click="handleSelectPromo">
        Quero
      </button>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
/* tertiary-400 */
progress::-moz-progress-bar {
  background: theme('colors.tertiary.400');
}
progress::-webkit-progress-value {
  background: theme('colors.tertiary.400');
}
progress {
  color: theme('colors.tertiary.400');
}
</style>
