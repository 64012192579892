<script setup lang="ts">
import type { ICart } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

const { step } = useCheckoutStep()

const cart = useLocalStorage(LocalStorageKey.Cart, {} as ICart)
const loading = ref<boolean>(false)

function openModal() {
  if (step.value.index > 6)
    return

  usePromoModalOpen()
}

function handleRemoveCoupon() {
  saveCart({
    body: {
      coupon: '',
    },
    loading,
    onSuccess: () => {
      toast({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Cupom removido com sucesso.',
      })
    },
  })
}

const hasCoupon = computed(() => cart.value && cart.value.coupon)
</script>

<template>
  <div
    v-if="step.index <= 6 || hasCoupon"
    class="flex flex-col gap-2"
  >
    <div
      class="flex items-center justify-between gap-4 p-4 xl:px-8 cursor-pointer"
      :class="hasCoupon ? 'text-prima: loadingPromory-400 bg-primary-50' : 'text-primary-500 bg-base-50'"
      @click.prevent="openModal"
    >
      <div class="flex items-center justify-between gap-4">
        <div class="flex items-center justify-center rounded-full">
          <Icon :name="hasCoupon ? 'mdi:sale' : 'mdi:sale-outline'" class="size-6" />
        </div>

        <p class="text-sm xl:text-base font-bold">
          Cupom Trevo
        </p>
      </div>

      <button v-if="!hasCoupon" type="button" class="flex items-center gap-1 font-bold text-base">
        Inserir Cupom
      </button>

      <div v-else class="flex items-center gap-10">
        <span class="text-center font-bold max-w-[90px] break-all whitespace-nowrap">
          {{ cart.coupon }}
        </span>

        <button class="flex items-center p-2" @click.stop="handleRemoveCoupon">
          <span class="sr-only">Remover cupom</span>
          <Icon v-if="loading" name="mdi:loading" class="text-xl animate-spin" />
          <Icon v-else name="mdi:close" class="text-xl" />
        </button>
      </div>
    </div>

    <div v-if="hasCoupon && cart.esferaPoints" class="bg-warning-200 text-neutral-400 font-bold py-2 px-4 text-xs rounded-full text-center">
      Ao comprar, você receberá {{ cart.esferaPoints }} PTS Esfera
    </div>
  </div>
</template>
