<script setup lang="ts">
import type { IBranch, IBundleItem, IBundleNewJourney, ICart, ICartDetail, IScheduleGroup } from '@/types/types'
import { CHECKOUT_STEPS, EventName, LocalStorageKey, SessionStorageKey } from '@/types/enums'

defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  hideChangeBranch: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])

const branchesSelected = useSessionStorage(SessionStorageKey.BranchesSelected, [])

const selectedBundle = useSessionStorage<IBundleNewJourney>(SessionStorageKey.BundleSelected, {} as IBundleNewJourney)

const loading = ref<boolean>(false)

const branchSelected = ref<IBranch[]>([])
function handleSelectBranch(selection: IBranch, options = { remove: false }) {
  if (options.remove) {
    const index = branchSelected.value.findIndex(
      selected => selected.laboratoryId !== selection.laboratoryId,
    )

    branchSelected.value.splice(index, 1)

    return
  }

  const laboratory = selectedBundle.value?.bundle?.find(({ laboratoryId }) => laboratoryId === selection?.laboratoryId)

  track(EventName.SelectBranch, {
    tagAtHome: selection.laboratoryBranchAtHome,
    tagNeedSchedule: selection.laboratoryBranchNeedSchedule,
    laboratoryName: laboratory?.laboratoryName,
    branchName: selection.laboratoryBranchName,
  })

  branchSelected.value.push(selection)
  branchesSelected.value = branchSelected.value
}

const cart = useLocalStorage(LocalStorageKey.Cart, {} as ICart)

const { schedulesFormatted } = useSchedule()
async function goToCheckout() {
  track(EventName.AddCart)

  const cartDetails: ICartDetail[] = selectedBundle.value.bundle.flatMap(({ procedures, laboratoryId, laboratoryLogo, laboratoryName }: IBundleItem) => {
    return procedures.map(procedure => ({ laboratoryId, ...procedure, laboratoryLogo, laboratoryName }))
  }).map((procedure) => {
    let procedureScheduleGroupIndex: any = 0
    for (const index in selectedBundle.value.scheduleGroups) {
      const searched = selectedBundle.value.scheduleGroups[index].scheduleGroups.find(p => p.procedureDisplaySlug === procedure.procedureDisplaySlug)

      if (searched) {
        procedureScheduleGroupIndex = Number.parseInt(index)
        break
      }
    }

    const branch: IBranch = branchSelected.value.find(({ groupIndex }: IBranch) => groupIndex === procedureScheduleGroupIndex) as IBranch

    let scheduleOptions = null

    if (procedure.needSchedule) {
      scheduleOptions = schedulesFormatted.value[branch.laboratoryBranchId]
    }

    return {
      labId: procedure.laboratoryId,
      labBranchId: branch.laboratoryBranchId,
      labName: procedure.laboratoryName,
      needPrescription: procedure.needPrescription,
      labLogo: procedure.laboratoryLogo,
      labBranchProcedureName: branch.laboratoryBranchName,
      labBranchProcedurePrice: procedure.price,
      procedureId: procedure.procedureId,
      displayNameId: procedure.procedureDisplayId,
      scheduleOptions,
      displayName: procedure.procedureName,
    }
  })

  const body: ICart = {
    sessionId: useTrackGoogleSessionId().value,
    cartDetails,
    digitalJourney: true,
    origin: 'website',
    installmentText: selectedBundle.value.installmentText,
    lastStep: CHECKOUT_STEPS.BUDGET,
    totalPrice: selectedBundle.value.bundlePrice,
    discount: selectedBundle.value.bundleDiscount,
  }

  useJumpPersonalData().value = true

  if (cart.value.coupon) {
    cart.value = {
      coupon: cart.value.coupon,
    }
  }
  else {
    cart.value = {}
  }

  saveCart(
    {
      body,
      loading,
      onSuccess: () => {
        if (useRoute().name === 'checkout') {
          emit('close')
          return
        }

        return navigateTo('/checkout')
      },
    },
  )
}

const { schedules } = useSchedule()

const goToCheckoutDisabled = computed(() => {
  let needSchedule = false

  for (const group of selectedBundle.value.scheduleGroups) {
    if (group.scheduleGroups.find(({ procedureNeedSchedule }) => procedureNeedSchedule)) {
      needSchedule = true
    }
  }

  if (selectedBundle.value.scheduleGroups?.length === branchSelected.value?.length) {
    if (!needSchedule)
      return false

    if (!Object.values(schedules.value)?.length)
      return true

    for (const option of Object.values(schedules.value)) {
      if (
        !option.firstOption
        || !option.firstOption.date
        || !option.firstOption.time
      ) {
        return true
      }
    }

    return false
  }

  return true
})

function closeModal() {
  branchSelected.value = []

  schedules.value = {}

  emit('close')
}

function laboratoryHasProcedure(labIndex: number, procedureDisplaySlug: string) {
  return !!selectedBundle.value.bundle[labIndex].procedures.find((procedure: any) => procedure.procedureDisplaySlug === procedureDisplaySlug)
}

function groupIsInLab(labIndex: number, group: IScheduleGroup) {
  for (const scheduleGroup of group.scheduleGroups) {
    if (laboratoryHasProcedure(labIndex, scheduleGroup.procedureDisplaySlug)) {
      return true
    }
  }

  return false
}

function filterGroupsByLaboratory(labIndex: number) {
  let groups = selectedBundle.value.scheduleGroups

  groups = groups.map((group, index: number) => ({ ...group, index }))

  return groups.filter(group => groupIsInLab(labIndex, group))
}
</script>

<template>
  <SideSheet :show="show" title="Onde você busca atendimento?" @close-modal="closeModal">
    <template v-if="show && Object.keys(selectedBundle)?.length" #content>
      <div class="relative h-full w-full overflow-x-hidden mb-8">
        <p class="text-primary-500 text-base mt-2 mb-8 xl:px-8 px-4">
          Escolha a unidade e informe as datas que mais se encaixam em sua agenda para realizar os procedimentos, e nós
          iremos confirmar a disponibilidade com o laboratório.
        </p>

        <TAccordion
          v-for="(laboratory, laboratoryIndex) in selectedBundle.bundle"
          id="laboratories"
          :key="`laboratory-${laboratoryIndex}`"
          open
          without-border-bottom
        >
          <template #header>
            <div class="flex items-center">
              <div class="flex items-center gap-3 flex-1">
                <Img
                  :src="laboratory.laboratoryLogo"
                  :alt="`Logo do laboratório ${laboratory.laboratoryName}`"
                  classes="w-10 h-10 rounded-lg"
                />

                <span class="text-base font-bold text-primary-400">
                  {{ laboratory.laboratoryName }}
                </span>
              </div>

              <span class="text-primary-500 text-base">
                {{ laboratory.procedures?.length }} de {{ useProcedures().procedures.value?.length || cart?.cartDetails?.length }} exames
              </span>
            </div>
          </template>

          <template #content>
            <ul
              v-for="group in filterGroupsByLaboratory(laboratoryIndex)"
              :key="`group-${laboratoryIndex}-${group.index}`"
              class="border-b border-b-neutral-50 xl:px-8 px-4"
            >
              <span
                v-for="scheduleGroup in group.scheduleGroups"
                :key="scheduleGroup.procedureDisplaySlug"
                class="flex flex-col py-2 text-primary-500 text-base"
              >
                {{ scheduleGroup.procedureName }}
              </span>

              <li class="flex flex-col xl:flex-row flex-wrap">
                <ProcedureBranchListItem
                  :laboratory="laboratory"
                  :group-index="group.index"
                  :hide-change-branch="hideChangeBranch"
                  @select-branch="handleSelectBranch"
                />
              </li>
            </ul>
          </template>
        </TAccordion>
      </div>

      <div class="xl:px-8 px-4 fixed bottom-0 w-full py-4 border border-top-neutral-50 bg-white">
        <Button
          data-testid="schedule-procedure-button"
          class="w-fit"
          :loading="loading"
          :disabled="goToCheckoutDisabled"
          @click="goToCheckout"
        >
          {{ selectedBundle.tags.needSchedule ? 'Agendar' : 'Comprar' }}
        </Button>
      </div>
    </template>
  </SideSheet>
</template>
