<script lang="ts" setup>
import type { IBranch, IBundle, IBundleItem, ICart, ICartDetail, IProcedure } from '@/types/types'
import { EventName, LocalStorageKey, SessionStorageKey } from '@/types/enums'

const props = defineProps({
  bundle: {
    type: Array as PropType<IBundleItem[]>,
    default: () => [],
  },

  selectedBundle: {
    type: Object as PropType<IBundle>,
    default: () => {},
  },

  isCheckout: {
    type: Boolean,
    default: true,
  },

  isRounded: {
    type: Boolean,
    default: false,
  },

  showDetails: {
    type: Boolean,
    default: true,
  },

  enableRemoveProcedure: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['close', 'handleShowPromo'])

const bundleSelected = useSessionStorage(SessionStorageKey.BundleSelected, {})

const discountByBundle = computed(() => {
  if (!props.bundle.length)
    return 0

  return props.bundle.reduce((acc: number, item: IBundleItem) => acc + item.laboratoryDiscount, 0)
})

const content = useContent()
const branchesSelected = useSessionStorage(SessionStorageKey.BranchesSelected, [])
const cart = useLocalStorage(LocalStorageKey.Cart, {} as ICart)
const { data: coupons } = useUserCouponList()
const { step } = useCheckoutStep()

const cartDetailsByLaboratory = computed(() => {
  const details: ICartDetail[] = []
  if (props.bundle.length) {
    return props.bundle.map((item: IBundleItem) => ({
      labId: item.laboratoryId,
      labLogo: item.laboratoryLogo,
      labName: item.laboratoryName,
      labBranchName: null,
      labBranchProcedureName: null,
      labBranchProcedurePrice: item.laboratoryPrice,
      discount: item.laboratoryDiscount.toString(),
    }))
  }

  if (!cart.value || !cart.value.cartDetails)
    return []

  cart.value.cartDetails.forEach((item: ICartDetail) => {
    if (!details.find(lab => lab.labId === item.labId))
      details.push(item)
  })

  return details
})

const { removeSlug, procedures } = useProcedures()

async function handleRemoveSlug(procedure: ICartDetail) {
  track(EventName.ExamRemove, {
    displayName: procedure.displayName,
    procedureDisplayId: procedure.procedureId,
  })

  await removeSlug(procedure.slug)

  if (useRoute().name === 'checkout') {
    cart.value = {}
    useSessionStorage(SessionStorageKey.BundleSelected, null).value = null

    if (!useProcedureSlug().value.length)
      return await navigateToWithLocation('/')

    return await navigateToWithLocation(`/exames?exames=${useProcedureSlug().value.join(',')}`)
  }

  emit('close')

  if (useRoute().name === 'checkout')
    await navigateToWithLocation(`/exames?exames=${useProcedureSlug().value.join(',')}`)
}

const showCouponToast = ref(false)

function proceduresByLaboratoryId(laboratoryId: string) {
  if (Object.keys(props.bundle).length) {
    const bundle = props.bundle
      .find((item: IBundleItem) => item.laboratoryId === laboratoryId)

    return bundle?.procedures.map((procedure: IProcedure) => ({
      discount: bundle.laboratoryDiscount,
      displayName: procedure.procedureName,
      labBranchProcedurePrice: procedure.price,
      procedureId: procedure.procedureId,
      procedure: procedure.procedureDisplaySlug,
    }))
  }

  if (!cart.value || !cart.value.cartDetails)
    return []

  return cart.value.cartDetails.filter((procedure: any) => procedure.labId === laboratoryId)
}

function getAddressByLabBranchId(branchId: string) {
  if (!branchesSelected.value.length)
    return '-'

  const branch = branchesSelected.value.find((branch: IBranch) => branch.laboratoryBranchId === branchId)

  if (branch?.laboratoryBranchAtHome) {
    return 'Coleta domiciliar'
  }
  else {
    return formatAddress({
      address: branch?.laboratoryBranchAddressOne as string,
      city: branch?.laboratoryBranchCity as string,
      district: branch?.laboratoryBranchDistrict as string,
      number: branch?.laboratoryBranchNumber as string,
    })
  }
}

function getPriceByLaboratory(laboratoryId: string) {
  return proceduresByLaboratoryId(laboratoryId)
    ?.reduce((acc: number, item: any) => acc + item.labBranchProcedurePrice, 0)
}

function getDiscountByLaboratory(laboratoryId: string) {
  if (props.bundle.length) {
    return proceduresByLaboratoryId(laboratoryId)
      ?.reduce((acc: number, item: any) => acc + Number.parseFloat(item.discount), 0) || 0
  }

  return cart.value?.cartDetails
    .filter(item => item.labId === laboratoryId)
    .reduce((acc, item) => acc + item.discount, 0) || 0
}

function getBranchByLaboratoryId(labId: string) {
  if (!branchesSelected.value.length)
    return ''

  return branchesSelected.value.find((branch: IBranch) => branch.laboratoryId === labId)
}

const showBranchSidesheet = ref(false)

const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')
onNuxtReady(() => {
  if (accessToken.value) {
    if (coupons.value?.length)
      showCouponToast.value = true
  }
})
</script>

<template>
  <div>
    <div :class="props.showDetails ? 'block' : 'xl:block hidden'">
      <div v-for="laboratory in cartDetailsByLaboratory" :key="laboratory.labId">
        <TAccordion id="cart-total">
          <template #header>
            <div class="flex items-center justify-between">
              <div class="flex items-center gap-4">
                <Img
                  :src="isMultiLab() ? laboratory.labLogo : bundleSelected?.bundle[0]?.laboratoryLogo"
                  :alt="`Logo do laboratório ${laboratory.labName}`"
                  classes="w-10 h-10 rounded-lg"
                />
                <div class="flex flex-col">
                  <h4 class="text-base font-bold text-primary-500">
                    {{ laboratory.labName || content.sellerName }}
                  </h4>
                  <span class="text-neutral-200 text-sm">
                    {{ proceduresByLaboratoryId(laboratory.labId).length }} de {{ procedures.length || cart?.cartDetails?.length }}
                  </span>
                </div>
              </div>

              <div class="flex flex-col items-center text-primary-500">
                <h4 v-if="getDiscountByLaboratory(laboratory.labId)" class="text-base font-semibold line-through">
                  {{ formatCurrency(getPriceByLaboratory(laboratory.labId)) }}
                </h4>

                <h4 class="text-xl">
                  {{ formatCurrency(getPriceByLaboratory(laboratory.labId) - getDiscountByLaboratory(laboratory.labId)) }}
                </h4>
              </div>
            </div>
          </template>

          <template #content>
            <div>
              <div
                v-if="isCheckout && step.index > 6"
                class="bg-success-100 xl:px-8 px-4 py-1"
                :class="laboratory.scheduleOptions ? 'text-tertiary-500 bg-tertiary-50' : 'text-success-300 bg-success-100'"
              >
                <span
                  class="text-base"
                >
                  {{ laboratory.scheduleOptions ? 'Aguardando confirmação de agendamento' : 'Liberado para realização' }}
                </span>
              </div>

              <div
                v-for="(procedure, index) in proceduresByLaboratoryId(laboratory.labId)"
                :key="index"
                class="text-primary-500 py-2 px-4 xl:px-8"
              >
                <div class="flex justify-between items-center">
                  <p class="text-base w-full">
                    {{ procedure.displayName }}
                  </p>

                  <div class="flex items-center gap-4">
                    <span class="text-base whitespace-nowrap">
                      {{ formatCurrency(procedure.labBranchProcedurePrice) }}
                    </span>

                    <Icon
                      v-if="props.enableRemoveProcedure"
                      name="mdi:close"
                      class="w-6 h-6 cursor-pointer"
                      @click="handleRemoveSlug(procedure)"
                    />
                  </div>
                </div>

                <div class="flex justify-between items-end">
                  <div class="flex flex-col">
                    <div v-if="isCheckout && branchesSelected.length" class="flex items-center gap-4 mt-2">
                      <Icon
                        name="mdi:map-marker-outline"
                        class="w-6 h-6"
                        width="14"
                        height="20"
                      />

                      <p class="flex flex-col text-sm py-2">
                        <span class="text-primary-500">
                          {{
                            getAddressByLabBranchId(procedure.labBranchId)
                          }}
                        </span>

                        <span v-if="isCheckout && !procedure.scheduleOptions" class="text-neutral-200 font-bold">
                          Coleta : {{ openingHours(getBranchByLaboratoryId(laboratory.labId)?.laboratoryBranchOpeningHours) }}
                        </span>
                      </p>
                    </div>

                    <div class="flex justify-between items-center">
                      <template v-if="isCheckout && procedure.scheduleOptions && procedure.scheduleOptions?.firstOption?.time">
                        <div class="flex items-center gap-4 xl:mr-4">
                          <Icon
                            name="mdi:calendar-outline"
                            class="w-[25px] h-[21px] text-primary-500"
                            height="18"
                            width="20"
                          />

                          <div class="flex flex-col">
                            <span class="text-primary-500 font-semibold text-xs">
                              Data sugerida
                            </span>

                            <span class="text-base text-neutral-200">
                              {{ procedure.scheduleOptions?.firstOption.date }}
                            </span>
                          </div>
                        </div>

                        <div class="flex flex-col">
                          <span class="text-primary-500 font-semibold text-xs">
                            Horário
                          </span>

                          <span class="text-base text-neutral-200">
                            {{ procedure.scheduleOptions?.firstOption.time }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>

                  <span v-if="step.index < 8 && props.isCheckout && branchesSelected?.length" class="text-primary-400 text-xs font-semibold cursor-pointer" @click="showBranchSidesheet = true">
                    Trocar
                  </span>
                </div>
              </div>
            </div>
          </template>
        </TAccordion>
      </div>
    </div>

    <CartPromoResume v-if="isTrevo()" />

    <teleport to="body">
      <div class="absolute top-[68px] w-fit flex left-1/2 transform -translate-x-1/2 mx-auto z-10">
        <CartCupomToast
          v-if="showCouponToast && coupons.length && step.index <= 6"
          :promo="coupons[0]"
          @promo-selected="usePromoModalOpen"
          @close="showCouponToast = false"
        />
      </div>
    </teleport>
    <div
      v-if="(cart && cart.discount) || discountByBundle"
      class="flex justify-between bg-base-50 font-bold text-base px-4 xl:px-8 py-4 text-primary-500"
    >
      <span>Descontos</span>
      <span>{{ `- ${formatCurrency(cart.discount || discountByBundle)}` }}</span>
    </div>

    <div
      class="items-center bg-base-50 text-primary-500 px-4 xl:px-8 py-1 text-base xl:text-xl font-bold xl:font-bold"
      :class="{
        'rounded-b-lg': isRounded,
        'border-t border-neutral-50': isTrevo(),
      }"
    >
      <div class="flex items-center justify-between">
        <span> Total </span>
        <div class="flex items-end flex-col">
          <template v-if="bundle.length">
            <span>
              {{ selectedBundle.installmentText }} sem juros
            </span>
            <span>
              ou {{ formatCurrency(selectedBundle.price) }}
            </span>
          </template>

          <template v-else>
            <span>{{ cart.installmentText }} sem juros</span>
            <span>ou {{ formatCurrency(cart.price || cart.total) }}</span>
          </template>
        </div>
      </div>
    </div>

    <ProcedureBranchSidesheet
      :show="showBranchSidesheet"
      @close="showBranchSidesheet = false"
    />
  </div>
</template>
