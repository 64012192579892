<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  open: {
    type: Boolean,
    default: false,
  },

  withoutBorderBottom: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['updateOpen'])

const isOpen = ref(false)

function toggle() {
  isOpen.value = !isOpen.value

  emit('updateOpen', isOpen.value ? props.id : '')
}

watch(
  () => props.open,
  () => {
    isOpen.value = props.open
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div>
    <div
      class="flex items-center justify-between rounded-t-lg w-full p-4 xl:px-8 cursor-pointer bg-white border-b-neutral-50"
      :class="withoutBorderBottom ? 'border-b-none' : 'border-b'"
      @click="toggle"
    >
      <div class="flex-1 mr-4">
        <slot name="header" />
      </div>

      <button
        aria-label="Botão para alternar entre aberto e fechado"
        type="button"
      >
        <Icon
          name="mdi:chevron-down"
          class="w-6 h-6 duration-300 text-primary-400"
          :class="isOpen ? 'rotate-180 duration-300' : ''"
        />
      </button>
    </div>

    <div class="relative">
      <div
        class="transition-all duration-500 ease-in-out overflow-hidden bg-white"
        :class="isOpen ? 'max-h-[100%] opacity-100' : 'max-h-0 opacity-0'"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
