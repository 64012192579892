import type { IScheduleOption } from '@/types/types'

interface IScheduleFirstOption {
  firstOption: IScheduleOption
}

interface ISchedule {
  [key: string]: IScheduleFirstOption
}

export default () => {
  const schedules = useState<ISchedule>('Schedules', () => ({}) as ISchedule)

  return {
    schedules,
    schedulesFormatted: computed(() => {
      const keys = Object.keys(schedules.value)

      const formatted = {} as ISchedule

      for (const key of keys) {
        formatted[key] = {
          firstOption: {
            date: new Date(schedules.value[key].firstOption.date).toLocaleDateString('pt-BR'),
            time: schedules.value[key].firstOption.time,
          },
        }
      }

      return formatted
    }),
  }
}
