interface ITimeSlot {
  value: string
  text: string
}

interface IOpeningHours {
  start: string
  end: string
}

const PERIODS: ITimeSlot[] = [
  { value: '02:00 às 04:00', text: '02:00 às 04:00' },
  { value: '04:00 às 06:00', text: '04:00 às 06:00' },
  { value: '06:00 às 08:00', text: '06:00 às 08:00' },
  { value: '08:00 às 10:00', text: '08:00 às 10:00' },
  { value: '10:00 às 12:00', text: '10:00 às 12:00' },
  { value: '12:00 às 14:00', text: '12:00 às 14:00' },
  { value: '14:00 às 16:00', text: '14:00 às 16:00' },
  { value: '16:00 às 18:00', text: '16:00 às 18:00' },
  { value: '18:00 às 20:00', text: '18:00 às 20:00' },
  { value: '20:00 às 22:00', text: '20:00 às 22:00' },
]

const DEFAULT_SLOTS: ITimeSlot[] = [
  { value: '08:00 às 10:00', text: '08:00 às 10:00' },
  { value: '10:00 às 12:00', text: '10:00 às 12:00' },
  { value: '12:00 às 14:00', text: '12:00 às 14:00' },
  { value: '14:00 às 16:00', text: '14:00 às 16:00' },
  { value: '16:00 às 18:00', text: '16:00 às 18:00' },
]

function parseHourToInt(hour: string): number {
  return Number.parseInt(hour.replace(':', ''))
}

function splitTimeRange(timeRange: string): [string, string] {
  const [start, end] = timeRange.split('às').map(time => time.trim())
  return [start, end]
}

function createTimeSlot(start: string, end: string): ITimeSlot {
  const value = `${start} às ${end}`.trim()
  return { value, text: value }
}

function adjustTimeSlot(slot: ITimeSlot, newStart?: string, newEnd?: string): ITimeSlot {
  const [currentStart, currentEnd] = splitTimeRange(slot.value)
  return createTimeSlot(
    newStart ?? currentStart,
    newEnd ?? currentEnd,
  )
}

/**
 * Gera um array de slots de horário baseado no horário de funcionamento fornecido.
 * A função divide o tempo entre o horário de abertura e fechamento em slots de 2 horas.
 *
 * @param {IOpeningHours} openingHours - Horário de funcionamento
 * @param {string} openingHours.start - Horário de início no formato HH:mm
 * @param {string} openingHours.end - Horário de término no formato HH:mm
 * @returns {ITimeSlot[]} Array de slots de horário disponíveis onde cada slot contém:
 *   - value: String do intervalo de tempo no formato "HH:mm às HH:mm"
 *   - text: Texto para exibição (igual ao value)
 * @example
 *   getSlotsByOpeningHours({ start: "08:00", end: "12:00" })
 *   // Retorna: [
 *   //   { value: "08:00 às 10:00", text: "08:00 às 10:00" },
 *   //   { value: "10:00 às 12:00", text: "10:00 às 12:00" }
 *   // ]
 */
export default function getSlotsByOpeningHours(openingHours: IOpeningHours): ITimeSlot[] {
  if (!openingHours) {
    return DEFAULT_SLOTS
  }

  const start = parseHourToInt(openingHours.start)
  const end = parseHourToInt(openingHours.end)

  const availableSlots = PERIODS.filter((period) => {
    const [periodStart, periodEnd] = splitTimeRange(period.value)
    const periodStartInt = parseHourToInt(periodStart)
    const periodEndInt = parseHourToInt(periodEnd)

    return periodStartInt >= start && periodEndInt <= end
  })

  if (!availableSlots.length) {
    return []
  }

  const result = [...availableSlots]
  const firstSlot = result[0]
  const lastSlot = result[result.length - 1]

  const [firstSlotStart] = splitTimeRange(firstSlot.value)
  const [, lastSlotEnd] = splitTimeRange(lastSlot.value)

  const openingDifference = start - parseHourToInt(firstSlotStart)
  const closingDifference = end - parseHourToInt(lastSlotEnd)

  if (openingDifference !== 0) {
    result[0] = adjustTimeSlot(firstSlot, openingHours.start)
  }

  if (closingDifference !== 0) {
    result[result.length - 1] = adjustTimeSlot(lastSlot, undefined, openingHours.end)
  }

  return result
}
